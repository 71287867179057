<template>
  <div
    id="diagram-donut"
    class="
      donut-diagram
      part-component part-diagram
      flex
      layout-align-start-start layout-column
    "
  >
    <div class="diagram-wrap" :style="{ backgroundImage: `url(${image})` }">
      <svg
        id="circle2"
        class="absolute-center"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="300px"
        height="300px"
        viewBox="0 0 300 300"
        enable-background="new 0 0 300 300"
        xml:space="preserve"
      >
        <circle cx="150" cy="150" r="150" fill="#000" />
        <g>
          <use xlink:href="#criclePath" fill="none" />
          <text fill="red">
            <textPath xlink:href="#criclePath">
              test2 Londolozi Adapted Donut Economy Londolozi Adapted Donut
              Economy
            </textPath>
          </text>
        </g>
      </svg>
      <svg
        id="circle3"
        class="absolute-center"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="300px"
        height="300px"
        viewBox="0 0 300 300"
        enable-background="new 0 0 300 300"
        xml:space="preserve"
      >
        <circle cx="150" cy="150" r="150" fill="#000" />
        <g>
          <use xlink:href="#criclePath" fill="none" />
          <text fill="blue">
            <textPath xlink:href="#txt-path">
              test2 Londolozi st2 Londolozi Adapted Donut Economy Londolozi
              Adapted Donut Economy
            </textPath>
          </text>
        </g>
        <g>
          <use xlink:href="#criclePath" fill="none" />
          <text fill="yellow">
            <textPath xlink:href="#txt-path2">
              test2 Londolozi st2 Londolozi Adapted Donut Economy Londolozi
              Adapted Donut Economy
            </textPath>
          </text>
        </g>
      </svg>

      <svg
        id="circdefs"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="300px"
        height="300px"
        viewBox="0 0 300 300"
        enable-background="new 0 0 300 300"
        xml:space="preserve"
      >
        <defs>
          <path
            id="criclePath2"
            d=" 
        M 150, 150 m -120, 0 a 120,120 0 0,1 240,0 a 120,120 0 0,1 -240,0 
        "
          />
          <path
            id="criclePath"
            d=" 
        M 150, 150 m -110, 0 a 110,110 0 0,1 220,0 a 110,110 0 0,1 -220,0 
"
          />

          <path
            d="M 150, 30 m -120, 120a120, 120 0 1, 0 240, 0a120, 120 0 1, 0 -240, 0"
            id="txt-path"
          ></path>
          <path
            d="M 150, 10 m -140, 140a140, 140 0 1, 0 280, 0a140, 140 0 1, 0 -280, 0"
            id="txt-path2"
          ></path>
        </defs>
      </svg>
    </div>
    <h3
      ref="title"
      class="
        diagram-heading
        normal
        parallax-item parallax-relative
        section-heading
      "
      data-depth="1.8"
    >
      {{ title }}
    </h3>
  </div>
</template>

<script>
import { partComponent } from "@/mixins/core.js";

export default {
  name: "DiagramDonut",
  mixins: [partComponent],
  props: ["show", "image"],
  data() {
    return {
      title: "Londolozi Adapted Donut Economy",
    };
  },
  methods: {},
};
</script>

<style scoped>
circle {
  opacity: 0.2;
}
#circle2 {
  /* width: 405px;
	height: 405px; */
}
.diagram-wrap {
  opacity: 1;
  width: 658px;
  height: 658px;
  font-size: 1rem;
  background-size: 100%;
  position: relative;
}
.donut-diagram h3 {
  margin: 2.2rem 0 0 4.3rem;
}
img {
  display: block;
}
</style>
